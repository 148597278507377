@charset "UTF-8";
@keyframes plyr-progress {
  to {
    background-position: 25px 0; } }

.plyr {
  position: absolute;
  width: 100%;
  height: 100%;
  font-family: Avenir, "Avenir Next", "Helvetica Neue", "Segoe UI", Helvetica, Arial, sans-serif;
  direction: ltr; }
  .plyr,
  .plyr *,
  .plyr *::after,
  .plyr *::before {
    box-sizing: border-box; }
  .plyr a, .plyr button, .plyr input, .plyr label {
    touch-action: manipulation; }
  .plyr:focus {
    outline: 0; }
  .plyr video,
  .plyr audio {
    width: 100%;
    height: auto;
    vertical-align: middle;
    border-radius: inherit; }
  .plyr input[type='range'] {
    display: block;
    height: 20px;
    width: 100%;
    margin: 0;
    padding: 0;
    vertical-align: middle;
    appearance: none;
    cursor: pointer;
    border: none;
    background: transparent; }
    .plyr input[type='range']::-webkit-slider-runnable-track {
      height: 8px;
      background: transparent;
      border: 0;
      border-radius: 4px;
      user-select: none; }
    .plyr input[type='range']::-webkit-slider-thumb {
      -webkit-appearance: none;
      margin-top: -4px;
      position: relative;
      height: 16px;
      width: 16px;
      background: #fff;
      border: 2px solid transparent;
      border-radius: 100%;
      transition: background .2s ease, border .2s ease, transform .2s ease;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0), 0 0 0 1px rgba(0, 0, 0, 0.15);
      box-sizing: border-box; }
    .plyr input[type='range']::-moz-range-track {
      height: 8px;
      background: transparent;
      border: 0;
      border-radius: 4px;
      user-select: none; }
    .plyr input[type='range']::-moz-range-thumb {
      position: relative;
      height: 16px;
      width: 16px;
      background: #fff;
      border: 2px solid transparent;
      border-radius: 100%;
      transition: background .2s ease, border .2s ease, transform .2s ease;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0), 0 0 0 1px rgba(0, 0, 0, 0.15);
      box-sizing: border-box; }
    .plyr input[type='range']::-ms-track {
      height: 8px;
      background: transparent;
      border: 0;
      color: transparent; }
    .plyr input[type='range']::-ms-fill-upper {
      height: 8px;
      background: transparent;
      border: 0;
      border-radius: 4px;
      user-select: none; }
    .plyr input[type='range']::-ms-fill-lower {
      height: 8px;
      background: transparent;
      border: 0;
      border-radius: 4px;
      user-select: none;
      background: var(--navy); }
    .plyr input[type='range']::-ms-thumb {
      position: relative;
      height: 16px;
      width: 16px;
      background: #fff;
      border: 2px solid transparent;
      border-radius: 100%;
      transition: background .2s ease, border .2s ease, transform .2s ease;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0), 0 0 0 1px rgba(0, 0, 0, 0.15);
      box-sizing: border-box;
      margin-top: 0; }
    .plyr input[type='range']::-ms-tooltip {
      display: none; }
    .plyr input[type='range']:focus {
      outline: 0; }
    .plyr input[type='range']::-moz-focus-outer {
      border: 0; }
    .plyr input[type='range'].tab-focus:focus {
      outline-offset: 3px; }
    .plyr input[type='range']:active::-webkit-slider-thumb {
      background: transparent;
      border-color: #fff;
      transform: scale(1.25); }
    .plyr input[type='range']:active::-moz-range-thumb {
      background: transparent;
      border-color: #fff;
      transform: scale(1.25); }
    .plyr input[type='range']:active::-ms-thumb {
      background: transparent;
      border-color: #fff;
      transform: scale(1.25); }

.plyr--video input[type='range'].tab-focus:focus {
  outline: 1px dotted rgba(0, 0, 0, 0); }

.plyr--audio input[type='range'].tab-focus:focus {
  outline: 1px dotted rgba(86, 93, 100, 0.5); }

.plyr__sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  position: absolute !important;
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important; }

.plyr__video-wrapper {
  position: relative;
  background: #000;
  border-radius: inherit; }

.plyr__video-embed {
  padding-bottom: 56.25%;
  /* 16:9 */
  height: 0;
  border-radius: inherit;
  overflow: hidden;
  z-index: 0; }
  .plyr__video-embed iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    user-select: none; }
  .plyr__video-embed > div {
    position: relative;
    padding-bottom: 200%;
    transform: translateY(-35.95%); }

.plyr .plyr__video-embed iframe {
  pointer-events: none; }

.plyr video::-webkit-media-text-track-container {
  display: none; }

.plyr__captions {
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 2rem;
  transform: translateY(-6rem);
  transition: transform .3s ease;
  color: #fff;
  font-size: 16px;
  text-align: center;
  font-weight: 400; }
  .plyr__captions span {
    border-radius: 2px;
    padding: 0rem 1rem;
    background: rgba(0, 0, 0, 0.7);
    box-decoration-break: clone;
    line-height: 150%; }
  .plyr__captions span:empty {
    display: none; }
  @media (min-width: 768px) {
    .plyr__captions {
      font-size: 24px; } }

.plyr--captions-active .plyr__captions {
  display: block; }

.plyr--hide-controls .plyr__captions {
  transform: translateY(-2rem); }

@media (min-width: 1024px) {
  .plyr--fullscreen-active .plyr__captions {
    font-size: 32px; } }

.plyr ::-webkit-media-controls {
  display: none; }

.plyr__controls {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  text-align: center; }
  .plyr__controls > button,
  .plyr__controls .plyr__progress,
  .plyr__controls .plyr__time {
    margin-left: 0.5rem; }
    .plyr__controls > button:first-child,
    .plyr__controls .plyr__progress:first-child,
    .plyr__controls .plyr__time:first-child {
      margin-left: 0; }
  .plyr__controls .plyr__volume {
    margin-left: 0.5rem; }
  .plyr__controls [data-plyr="pause"] {
    margin-left: 0; }
  .plyr__controls button {
    position: relative;
    display: inline-block;
    flex-shrink: 0;
    overflow: visible;
    vertical-align: middle;
    padding: 0.7rem;
    border: 0;
    background: transparent;
    border-radius: 3px;
    cursor: pointer;
    transition: background .3s ease, color .3s ease, opacity .3s ease;
    color: inherit; }
    .plyr__controls button svg {
      width: 5rem;
      height: 5rem;
      display: block;
      fill: currentColor; }
    .plyr__controls button:focus {
      outline: 0; }
  .plyr__controls .icon--exit-fullscreen,
  .plyr__controls .icon--muted,
  .plyr__controls .icon--captions-on {
    display: none; }
  @media (min-width: 480px) {
    .plyr__controls > button,
    .plyr__controls .plyr__progress,
    .plyr__controls .plyr__time {
      margin-left: 1rem; } }

.plyr--hide-controls .plyr__controls {
  opacity: 0;
  pointer-events: none; }

.plyr--video .plyr__controls {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  padding: 1rem 1rem 1rem;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  color: transparent;
  transition: opacity .3s ease; }
  .plyr--video .plyr__controls button.tab-focus:focus, .plyr--video .plyr__controls button:hover {
    background: transparent;
    color: var(--navy); }
  .plyr--video .plyr__controls button svg {
    stroke: var(--navy);
    stroke-width: 0.25; }

.plyr--audio .plyr__controls {
  padding: 1rem;
  border-radius: inherit;
  background: #fff;
  border: 1px solid #dbe3e8;
  color: #565D64; }
  .plyr--audio .plyr__controls button.tab-focus:focus, .plyr--audio .plyr__controls button:hover {
    background: var(--navy);
    color: #fff; }

.plyr__play-large {
  display: none;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1rem;
  background: transparent;
  border: 4px solid currentColor;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  color: transparent;
  transition: all .3s ease; }
  .plyr__play-large svg {
    position: relative;
    left: 2px;
    width: 20px;
    height: 20px;
    display: block;
    fill: currentColor; }
  .plyr__play-large:focus {
    outline: 1px dotted rgba(0, 0, 0, 0); }

.plyr .plyr__play-large {
  display: inline-block; }

.plyr--audio .plyr__play-large {
  display: none; }

.plyr--playing .plyr__play-large {
  opacity: 0;
  visibility: hidden; }

.plyr__controls [data-plyr='pause'],
.plyr--playing .plyr__controls [data-plyr='play'] {
  display: none; }

.plyr--playing .plyr__controls [data-plyr='pause'] {
  display: inline-block; }

.plyr--fullscreen-active .icon--exit-fullscreen,
.plyr--muted .plyr__controls .icon--muted,
.plyr--captions-active .plyr__controls .icon--captions-on {
  display: block; }
  .plyr--fullscreen-active .icon--exit-fullscreen + svg,
  .plyr--muted .plyr__controls .icon--muted + svg,
  .plyr--captions-active .plyr__controls .icon--captions-on + svg {
    display: none; }

.plyr [data-plyr='captions'],
.plyr [data-plyr='fullscreen'] {
  display: none; }

.plyr--captions-enabled [data-plyr='captions'],
.plyr--fullscreen-enabled [data-plyr='fullscreen'] {
  display: inline-block; }

.plyr__tooltip {
  position: absolute;
  z-index: 2;
  bottom: 100%;
  margin-bottom: 1rem;
  padding: 0.5rem 0.75rem;
  pointer-events: none;
  opacity: 0;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 3px;
  color: #fff;
  font-size: 14px;
  line-height: 1.3;
  transform: translate(-50%, 10px) scale(0.8);
  transform-origin: 50% 100%;
  transition: transform .2s .1s ease, opacity .2s .1s ease; }
  .plyr__tooltip::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    left: 50%;
    transform: translateX(-50%);
    bottom: -4px;
    border-right: 4px solid transparent;
    border-top: 4px solid rgba(0, 0, 0, 0.7);
    border-left: 4px solid transparent;
    z-index: 2; }

.plyr button:hover .plyr__tooltip,
.plyr button.tab-focus:focus .plyr__tooltip,
.plyr__tooltip--visible {
  opacity: 1;
  transform: translate(-50%, 0) scale(1); }

.plyr button:hover .plyr__tooltip {
  z-index: 3; }

.plyr__controls button:first-child .plyr__tooltip {
  left: 0;
  transform: translate(0, 10px) scale(0.8);
  transform-origin: 0 100%; }
  .plyr__controls button:first-child .plyr__tooltip::before {
    left: 3.2rem; }

.plyr__controls button:last-child .plyr__tooltip {
  right: 0;
  transform: translate(0, 10px) scale(0.8);
  transform-origin: 100% 100%; }
  .plyr__controls button:last-child .plyr__tooltip::before {
    left: auto;
    right: 3.2rem;
    transform: translateX(50%); }

.plyr__controls button:first-child:hover .plyr__tooltip,
.plyr__controls button:first-child.tab-focus:focus .plyr__tooltip,
.plyr__controls button:first-child .plyr__tooltip--visible,
.plyr__controls button:last-child:hover .plyr__tooltip,
.plyr__controls button:last-child.tab-focus:focus .plyr__tooltip,
.plyr__controls button:last-child .plyr__tooltip--visible {
  transform: translate(0, 0) scale(1); }

.plyr__progress {
  display: none;
  position: relative;
  flex: 1; }
  .plyr__progress input[type="range"] {
    position: relative;
    z-index: 2; }
    .plyr__progress input[type="range"]::-webkit-slider-runnable-track {
      background: transparent; }
    .plyr__progress input[type="range"]::-moz-range-track {
      background: transparent; }
    .plyr__progress input[type="range"]::-ms-fill-upper {
      background: transparent; }
  .plyr__progress .plyr__tooltip {
    left: 0; }

.plyr .plyr__progress {
  display: inline-block; }

.plyr__progress--buffer,
.plyr__progress--played,
.plyr__volume--display {
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 8px;
  margin: -4px 0 0;
  padding: 0;
  vertical-align: top;
  appearance: none;
  border: none;
  border-radius: 100px; }
  .plyr__progress--buffer::-webkit-progress-bar,
  .plyr__progress--played::-webkit-progress-bar,
  .plyr__volume--display::-webkit-progress-bar {
    background: transparent; }
  .plyr__progress--buffer::-webkit-progress-value,
  .plyr__progress--played::-webkit-progress-value,
  .plyr__volume--display::-webkit-progress-value {
    background: currentColor;
    border-radius: 100px;
    min-width: 8px; }
  .plyr__progress--buffer::-moz-progress-bar,
  .plyr__progress--played::-moz-progress-bar,
  .plyr__volume--display::-moz-progress-bar {
    background: currentColor;
    border-radius: 100px;
    min-width: 8px; }
  .plyr__progress--buffer::-ms-fill,
  .plyr__progress--played::-ms-fill,
  .plyr__volume--display::-ms-fill {
    border-radius: 100px; }

.plyr__progress--played,
.plyr__volume--display {
  z-index: 1;
  color: var(--navy);
  background: transparent;
  transition: none; }
  .plyr__progress--played::-webkit-progress-value,
  .plyr__volume--display::-webkit-progress-value {
    min-width: 8px;
    max-width: 99%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    transition: none; }
  .plyr__progress--played::-moz-progress-bar,
  .plyr__volume--display::-moz-progress-bar {
    min-width: 8px;
    max-width: 99%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    transition: none; }
  .plyr__progress--played::-ms-fill,
  .plyr__volume--display::-ms-fill {
    display: none; }

.plyr__progress--buffer::-webkit-progress-value {
  transition: width .2s ease; }

.plyr__progress--buffer::-moz-progress-bar {
  transition: width .2s ease; }

.plyr__progress--buffer::-ms-fill {
  transition: width .2s ease; }

.plyr--video .plyr__progress--buffer,
.plyr--video .plyr__volume--display {
  background: rgba(255, 255, 255, 0.25); }

.plyr--video .plyr__progress--buffer {
  color: rgba(255, 255, 255, 0.25); }

.plyr--audio .plyr__progress--buffer,
.plyr--audio .plyr__volume--display {
  background: rgba(198, 214, 219, 0.67); }

.plyr--audio .plyr__progress--buffer {
  color: rgba(198, 214, 219, 0.67); }

.plyr--loading .plyr__progress--buffer {
  animation: plyr-progress 1s linear infinite;
  background-size: 25px 25px;
  background-repeat: repeat-x;
  background-image: linear-gradient(-45deg, rgba(0, 0, 0, 0.15) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, 0.15) 50%, rgba(0, 0, 0, 0.15) 75%, transparent 75%, transparent);
  color: transparent; }

.plyr--video.plyr--loading .plyr__progress--buffer {
  background-color: rgba(255, 255, 255, 0.25); }

.plyr--audio.plyr--loading .plyr__progress--buffer {
  background-color: rgba(198, 214, 219, 0.67); }

.plyr__time {
  display: inline-block;
  vertical-align: middle;
  font-size: 14px; }

.plyr__time + .plyr__time {
  display: none; }
  @media (min-width: 768px) {
    .plyr__time + .plyr__time {
      display: inline-block; } }
  .plyr__time + .plyr__time::before {
    content: '\2044';
    margin-right: 1rem; }

.plyr__volume {
  display: none; }

.plyr .plyr__volume {
  flex: 1;
  position: relative; }
  .plyr .plyr__volume input[type="range"] {
    position: relative;
    z-index: 2; }
  @media (min-width: 480px) {
    .plyr .plyr__volume {
      display: block;
      max-width: 60px; } }
  @media (min-width: 768px) {
    .plyr .plyr__volume {
      max-width: 100px; } }

.plyr--is-ios .plyr__volume,
.plyr--is-ios [data-plyr='mute'] {
  display: none !important; }

.plyr--fullscreen-active {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 10000000;
  background: #000;
  border-radius: 0 !important; }
  .plyr--fullscreen-active video {
    height: 100%; }
  .plyr--fullscreen-active .plyr__video-wrapper {
    height: 100%;
    width: 100%; }
  .plyr--fullscreen-active .plyr__video-embed {
    overflow: visible; }
  .plyr--fullscreen-active .plyr__controls {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0; }
  .plyr--fullscreen-active.plyr--vimeo .plyr__video-wrapper {
    height: 0;
    top: 50%;
    transform: translateY(-50%); }

@font-face {
  font-weight: 400;
  font-style: normal;
  font-family: 'America Compressed';
  src: url("../fonts/gt-america-compressed-regular-webfont.woff2") format("woff2"), url("../fonts/gt-america-compressed-regular-webfont.woff") format("woff"); }

@font-face {
  font-weight: 200;
  font-style: normal;
  font-family: 'America Compressed';
  src: url("../fonts/gt-america-compressed-light-webfont.woff2") format("woff2"), url("../fonts/gt-america-compressed-light-webfont.woff") format("woff"); }

@font-face {
  font-weight: 100;
  font-style: normal;
  font-family: 'America Compressed';
  src: url("../fonts/gt-america-compressed-thin-webfont.woff2") format("woff2"), url("../fonts/gt-america-compressed-thin-webfont.woff") format("woff"); }

@font-face {
  font-weight: 100;
  font-style: normal;
  font-family: 'America Extended';
  src: url("../fonts/gt-america-extended-light-webfont.woff2") format("woff2"), url("../fonts/gt-america-extended-light-webfont.woff") format("woff"); }

@font-face {
  font-weight: 400;
  font-style: normal;
  font-family: 'America Extended';
  src: url("../fonts/gt-america-extended-regular-webfont.woff2") format("woff2"), url("../fonts/gt-america-extended-regular-webfont.woff") format("woff"); }

@font-face {
  font-weight: 700;
  font-style: normal;
  font-family: 'America Extended';
  src: url("../fonts/gt-america-extended-medium-webfont.woff2") format("woff2"), url("../fonts/gt-america-extended-medium-webfont.woff") format("woff"); }

:root {
  --red: rgb(178,51,47);
  --navy: rgb(37,33,52);
  --yellow: rgb(224,191,73);
  --off-white: rgb(249,248,247);
  --light-grey: rgb(214,214,214);
  --primary-font-family: 'America Extended', Helvetica, Arial, sans-serif;
  --secondary-font-family: 'America Compressed', Helvetica, Arial, sans-serif;
  --base-font-size: 2.25vw;
  --xs-font-size: 30%;
  --sm-font-size: 60%;
  --lg-font-size: 200%;
  --xl-font-size: 510%;
  --base-width: 80vw;
  --base-margin: 10vw;
  --base-padding: 5vw;
  --court-line-width: .25rem;
  --xs-margin: .5rem;
  --sm-margin: 1rem;
  --md-margin: 2rem;
  --lg-margin: 5rem;
  --normal-transition: 0.25s; }
  @media (max-width: 1200px) {
    :root {
      --base-font-size: 3vw;
      --xs-font-size: 50%;
      --sm-font-size: 60%;
      --lg-font-size: 150%;
      --xl-font-size: 390%; } }
  @media (max-width: 600px) {
    :root {
      --base-font-size: 4vw;
      --xs-font-size: 50%;
      --sm-font-size: 100%;
      --lg-font-size: 150%;
      --xl-font-size: 390%;
      --base-width: 100vw;
      --base-margin: 0vw;
      --base-padding: 5vw;
      --xs-margin: 1rem;
      --sm-margin: 3rem;
      --md-margin: 4rem;
      --lg-margin: 7rem;
      --court-line-width: .2rem; } }

::selection {
  color: var(--light-grey);
  background: none; }

:focus {
  outline: none; }

html {
  font-family: var(--primary-font-family);
  font-size: var(--base-font-size); }

header, footer {
  color: var(--off-white);
  text-align: center; }

h2 {
  font-size: var(--sm-font-size);
  font-weight: normal; }
  @media (max-width: 1200px) {
    h2 {
      font-weight: 100;
      text-align: center; } }

.ContentBlock-item--ordered > section > h2:before {
  font-family: var(--secondary-font-family);
  letter-spacing: .1em; }

h3 {
  font-size: var(--base-font-size);
  font-weight: 700; }

p {
  font-weight: 100;
  line-height: 1.2em; }

small {
  font-size: var(--sm-font-size); }

footer small {
  font-size: var(--xs-font-size);
  font-weight: lighter; }

a {
  color: inherit;
  text-decoration: none;
  background-image: linear-gradient(to right, var(--navy) 33%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 4px 2px;
  background-repeat: repeat-x; }
  @media (max-width: 600px) {
    a {
      background-size: 4px 1px; } }
  a:hover {
    color: var(--red);
    background-image: linear-gradient(to right, var(--red) 33%, rgba(255, 255, 255, 0) 0%); }

.ContentBlock-item:before {
  text-align: center; }

.Slogan-item span {
  font-size: var(--xl-font-size);
  font-weight: bold;
  line-height: 0.98em;
  text-align: left;
  color: transparent;
  -webkit-text-stroke: 0.01em var(--navy); }

.Slogan-item.is-active span {
  color: var(--navy); }

.ContactList-item h3, .ContactList-item a {
  font-size: var(--sm-font-size); }

.NewsletterForm-inputWrapper, .NewsletterForm-submit {
  font-size: var(--xl-font-size); }

.NewsletterForm-input {
  font-size: inherit;
  font-family: var(--primary-font-family);
  font-weight: 700;
  color: var(--navy); }
  .NewsletterForm-input::placeholder {
    color: var(--light-grey); }

.MainNav-listItem {
  font-size: var(--sm-font-size);
  font-family: var(--secondary-font-family);
  font-weight: 200;
  letter-spacing: 0.075em; }
  .MainNav-listItem a, .MainNav-listItem span {
    background: none;
    color: white; }
  .MainNav-listItem span {
    font-family: var(--primary-font-family);
    letter-spacing: 0; }

.ContentBlock-item--ordered:before {
  font-size: var(--sm-font-size);
  font-family: var(--secondary-font-family);
  font-weight: 200;
  letter-spacing: 0.075em; }

.TeamList-memberInfo {
  line-height: 0.5em; }
  .TeamList-memberInfo h3, .TeamList-memberInfo span {
    font-size: var(--sm-font-size); }

.TeamList-memberEmail {
  font-size: var(--sm-font-size); }

pre {
  font-size: 12px;
  background-color: yellow;
  word-break: break-all; }

* {
  padding: 0;
  margin: 0;
  vertical-align: baseline;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  line-height: 1em; }

html {
  position: relative;
  overflow-y: visible;
  overflow-x: hidden;
  background-color: var(--yellow);
  color: var(--navy);
  -webkit-font-smoothing: subpixel-antialiased; }

body {
  position: relative;
  overflow-y: hidden;
  background-color: var(--off-white);
  overflow-x: hidden; }

header, footer {
  background-color: var(--navy); }

section {
  margin-top: var(--lg-margin);
  margin-bottom: var(--lg-margin); }

section:first-of-type {
  margin-top: var(--md-margin); }
  @media (max-width: 600px) {
    section:first-of-type {
      margin-top: var(--lg-margin); } }

main {
  width: var(--base-width);
  margin: auto;
  padding: 0 calc(var(--court-line-width) + var(--base-padding)); }

h2 {
  margin-bottom: var(--md-margin); }

p {
  margin-bottom: var(--sm-margin); }

ul, ol {
  list-style: none; }

img, svg {
  max-width: 100%; }

.ContentBlock {
  counter-reset: main-counter; }

.ContentBlock-item:before {
  position: absolute;
  left: 0;
  width: calc(var(--base-margin)); }
  @media (max-width: 600px) {
    .ContentBlock-item:before {
      display: none; } }

@media (max-width: 600px) {
  .ContentBlock-item h2:before {
    display: block;
    position: absolute; } }

@media (max-width: 1200px) {
  .ContentBlock-item--ordered:before {
    content: "0" counter(main-counter, decimal-leading-zero);
    counter-increment: main-counter;
    line-height: 1em; } }

@media (max-width: 600px) {
  .ContentBlock-item--ordered:before {
    content: counter(main-counter); } }

@media (max-width: 600px) {
  .ContentBlock-item--ordered > section > h2:before {
    content: "0" counter(main-counter, decimal-leading-zero);
    counter-increment: main-counter; } }

.ContentBlock-item--unordered:before {
  content: '●';
  font-size: var(--sm-font-size); }

@media (max-width: 600px) {
  .ContentBlock-item--unordered > section > h2:before {
    content: '●';
    font-size: var(--base-font-size); } }

.Courtline {
  pointer-events: none;
  z-index: 10; }

.Courtline--freeThrowCircle {
  position: absolute;
  height: 0;
  width: 70%;
  padding-bottom: 70%;
  border-radius: 50%;
  left: 50%;
  border: var(--court-line-width) solid var(--red);
  transform: translate(-50%, -50%); }
  @media (max-width: 600px) {
    .Courtline--freeThrowCircle {
      width: 90%;
      padding-bottom: 90%; } }

.Courtline--centerCircle {
  position: absolute;
  height: 0;
  width: 25%;
  padding-bottom: 25%;
  border-radius: 50%;
  left: 50%;
  border: var(--court-line-width) solid var(--red);
  transform: translate(-50%, -50%); }
  @media (max-width: 600px) {
    .Courtline--centerCircle {
      width: 30%;
      padding-bottom: 30%; } }

.Courtline--sideLine {
  position: absolute;
  top: 0;
  left: calc(var(--base-margin));
  width: var(--base-width);
  height: 100%;
  border-left: var(--court-line-width) solid var(--red);
  border-right: var(--court-line-width) solid var(--red); }

.Courtline--tickbox {
  background-color: var(--red);
  position: absolute;
  width: calc( var(--court-line-width) * 2);
  height: 100px; }
  @media (max-width: 600px) {
    .Courtline--tickbox {
      width: calc( var(--court-line-width) * 3);
      height: 15vw; } }

.Courtline--tickbox.is-onRight {
  left: calc(100% - var(--base-margin)); }
  @media (max-width: 600px) {
    .Courtline--tickbox.is-onRight {
      right: 0;
      left: unset; } }

.Courtline--tickbox.is-onLeft {
  right: calc(100% - var(--base-margin)); }
  @media (max-width: 600px) {
    .Courtline--tickbox.is-onLeft {
      left: 0;
      right: unset; } }

.Courtline--tickbox.is-onBottom {
  bottom: 0; }

.Courtline--tick {
  background-color: var(--red);
  position: absolute;
  height: var(--court-line-width);
  width: var(--base-padding);
  margin-top: calc( 0rem - (var(--lg-margin) / 2)); }
  @media (max-width: 600px) {
    .Courtline--tick {
      width: 10vw;
      margin-top: calc( 0rem - (var(--lg-margin) / 1.25)); } }

.Courtline--tick.is-onRight {
  left: calc(100% - var(--base-margin) - (var(--court-line-width) / 2));
  transform: translateY(-50%) translateX(-50%); }

.Courtline--tick.is-onLeft {
  right: calc(100% - var(--base-margin) - (var(--court-line-width) / 2));
  transform: translateY(-50%) translateX(50%); }

header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - var(--sm-margin));
  position: relative; }
  @media (max-width: 1200px) {
    header {
      height: 75vh; } }

.Lettermark {
  width: var(--base-width);
  height: auto; }

.Lettermark svg {
  width: 75%;
  fill: var(--off-white); }

.ShopBanner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1.75rem;
  background-color: var(--yellow);
  border: 0;
  color: var(--navy);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--sm-font-size); }

footer {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: var(--sm-margin);
  position: relative;
  height: 25vh; }
  @media (max-width: 600px) {
    footer {
      height: 30vw; } }

.Logomark {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%); }

.Logomark svg {
  width: 5rem;
  height: 5rem; }

.Logomark .ball {
  fill: var(--red); }

.Logomark .star {
  fill: var(--off-white); }

.MainNav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: var(--base-margin);
  text-align: center;
  mix-blend-mode: difference;
  padding-top: var(--md-margin);
  padding-bottom: var(--md-margin);
  z-index: 10; }
  @media (max-width: 1200px) {
    .MainNav {
      opacity: 0; } }

.MainNav-list {
  height: 100%; }

.MainNav-listItem {
  width: var(--base-margin);
  position: absolute;
  padding-bottom: .5em; }
  .MainNav-listItem span {
    position: absolute;
    white-space: nowrap;
    left: calc(var(--base-margin) + var(--court-line-width) + var(--base-padding));
    opacity: 0;
    transition: .25s opacity; }

.MainNav-listItem:not(.is-active):hover span {
  opacity: 1; }

.Anchor {
  display: block;
  position: relative;
  visibility: hidden; }

.AffiliateList-item {
  margin-bottom: var(--xs-margin); }

.AffiliateList-item::before {
  content: '';
  display: block;
  margin-top: .15em;
  width: .7em;
  height: .7em;
  background-color: var(--red);
  border-radius: 50%;
  box-sizing: border-box;
  position: absolute;
  left: calc( (var(--base-margin)) + (var(--court-line-width) / 2));
  transform: translateX(-50%);
  z-index: 11; }
  @media (max-width: 600px) {
    .AffiliateList-item::before {
      content: '○';
      display: inline;
      position: static;
      transform: unset;
      background-color: unset;
      border-radius: unset; } }

.AffiliateList-name {
  display: inline; }

.ContactList {
  display: flex;
  flex-wrap: wrap; }

.ContactList-item {
  flex: 0 0 50%;
  width: 50%;
  margin-bottom: var(--sm-margin); }
  @media (max-width: 1200px) {
    .ContactList-item {
      flex: 0 0 100%;
      width: 100%; } }

.Section--gallerySection {
  padding-bottom: 57.25%; }
  @media (max-width: 600px) {
    .Section--gallerySection {
      padding-bottom: 0; } }

.Gallery {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 15; }
  @media (max-width: 600px) {
    .Gallery {
      position: static; } }

.Gallery::before, .Gallery::after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  height: var(--court-line-width);
  width: 100%;
  background-color: var(--red);
  z-index: 1; }

.Gallery::before {
  transform: translateY(-50%); }

.Gallery::after {
  transform: translateY(-50%); }

.Gallery-inner {
  position: relative;
  padding-bottom: 38.75%;
  z-index: 0; }
  @media (max-width: 600px) {
    .Gallery-inner {
      position: static;
      padding-bottom: 0; } }

.GalleryToggle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 11;
  justify-content: space-between;
  pointer-events: none; }
  @media (max-width: 600px) {
    .GalleryToggle {
      display: none; } }

.GalleryToggle-prev, .GalleryToggle-next {
  pointer-events: all;
  flex: 0 0 25%;
  width: 25%; }

.GalleryToggle-next {
  cursor: -webkit-image-set(url("../img/cursor-arrow-e.svg") 1x, url("../img/cursor-arrow-e.svg") 2x) 30 22.5, auto; }

.GalleryToggle-prev {
  cursor: -webkit-image-set(url("../img/cursor-arrow-w.svg") 1x, url("../img/cursor-arrow-w.svg") 2x) 30 22.5, auto; }

.Gallery-slide {
  position: absolute;
  top: 0;
  left: calc( var(--base-margin) + var(--base-padding) + var(--court-line-width));
  width: calc( var(--base-width) - (var(--base-padding) * 2) - (var(--court-line-width) * 2));
  height: 100%;
  background-color: var(--navy); }
  @media (max-width: 600px) {
    .Gallery-slide {
      left: 0;
      right: auto;
      position: relative;
      margin-bottom: 1em;
      background-color: unset; } }
  .Gallery-slide:last-child {
    margin-bottom: calc( 0rem - var(--court-line-width) / 2); }

.Gallery-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
  vertical-align: bottom; }
  @media (max-width: 600px) {
    .Gallery-img {
      position: relative; } }

.Gallery-slide:hover .Gallery-img.is-duotone {
  opacity: 0; }

@media (max-width: 600px) {
  .Gallery-img.is-duotone {
    position: absolute;
    top: 0;
    left: 0; } }

.Gallery-slide.is-dockedRight {
  left: calc(100vw - var(--base-margin) + var(--base-padding)); }
  @media (max-width: 600px) {
    .Gallery-slide.is-dockedRight {
      left: 0;
      right: auto;
      z-index: 1 !important; } }

.Gallery-slide.is-dockedLeft {
  right: calc(100vw - var(--base-margin) + var(--base-padding));
  left: auto; }
  @media (max-width: 600px) {
    .Gallery-slide.is-dockedLeft {
      right: 0;
      right: auto;
      z-index: 1 !important; } }

.VideoEmbed {
  height: 0px;
  position: relative;
  padding-bottom: 56.25%; }

.VideoEmbed-item {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%; }

.Section--mapSection {
  background-color: var(--off-white); }

.MapEmbed {
  height: 0px;
  position: relative;
  padding-bottom: 40%;
  background-color: var(--light-grey);
  mix-blend-mode: darken; }

.MapEmbed-item {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  mix-blend-mode: lighten;
  filter: contrast(25%) saturate(0%) brightness(185%); }

.MapEmbed-overlay {
  z-index: 5;
  mix-blend-mode: unset;
  stroke: var(--navy);
  stroke-width: 0.35;
  fill: var(--navy);
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none; }

.MapEmbed-overlay svg {
  width: 20%; }

a[href^="http://maps.google.com/maps"] {
  display: none !important; }

a[href^="https://maps.google.com/maps"] {
  display: none !important; }

.gmnoprint a, .gmnoprint span, .gm-style-cc {
  display: none; }

.gmnoprint div {
  background: none !important; }

.NewsletterForm-input {
  display: block;
  width: 100%;
  border: none;
  background-color: unset; }

.NewsletterForm-submit {
  position: absolute;
  right: calc( var(--base-margin) / 2);
  transform: translateX(50%);
  width: calc( var(--base-margin) / 2);
  margin-top: .45em; }

.NewsletterForm-inputWrapper::before {
  margin-top: 0.325em; }

.NewsletterForm-inputWrapper::after {
  transform: translateY(-100%);
  margin-top: -0.325em; }

.NewsletterForm-inputWrapper::before, .NewsletterForm-inputWrapper::after {
  content: '';
  display: block;
  position: absolute;
  left: calc( (var(--base-margin) / 2) + (var(--base-padding) / 2));
  height: var(--court-line-width);
  width: calc( 100% - var(--base-margin) - var(--base-padding));
  background-color: var(--red);
  z-index: 10; }
  @media (max-width: 600px) {
    .NewsletterForm-inputWrapper::before, .NewsletterForm-inputWrapper::after {
      width: 100%;
      left: 0; } }

.Section--slogan {
  margin-bottom: calc(var(--lg-margin) * 1.5); }
  .Section--slogan span {
    display: block;
    text-align: center; }

.TeamList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

.TeamList-item {
  flex: 0 0 calc( 33% - ( var(--sm-margin) * (2 / 3) ));
  width: calc( 33% - ( var(--sm-margin) * (2 / 3) ));
  margin-bottom: var(--md-margin); }
  @media (max-width: 1200px) {
    .TeamList-item {
      flex: 0 0 100%;
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between; } }
  @media (max-width: 1200px) {
    .TeamList-item {
      margin-bottom: var(--sm-margin); } }

@media (max-width: 1200px) {
  .TeamList-memberImage {
    flex: 0 0 calc( 50% - calc(var(--sm-margin) / 2));
    width: calc( 50% - var(--sm-margin)); } }

.TeamList-memberInfo {
  margin-bottom: var(--xs-margin); }
  .TeamList-memberInfo h3 {
    margin-bottom: var(--xs-margin); }
  @media (max-width: 1200px) {
    .TeamList-memberInfo {
      flex: 0 0 calc( 50% - calc(var(--sm-margin) / 2));
      width: calc( 50% - var(--sm-margin)); } }

@media (min-width: 600px) {
  .u-sm-only {
    display: none !important; } }

@media (max-width: 600px) {
  .u-sm-up {
    display: none !important; } }

.u-z-top {
  z-index: 20; }
